import { useField } from "formik";

import classes from "./Style.module.scss";

const CustomSelect = ({
  name,
  defaultLabel = "",
  optionSelected = true,
  options = [],
  ...rest
}) => {
  const [fields, meta] = useField(name);
  return (
    <div className={classes["select-wrap"]}>
      <select
        className={`${classes.select} ${optionSelected ? classes.active : ""} ${
          meta.touched && meta.error ? classes.error : ""
        }`}
        {...fields}
        {...rest}
      >
        {defaultLabel ? (
          <option value="" disabled>
            {defaultLabel}
          </option>
        ) : null}

        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error && (
        <div className={classes["text-field-error"]}>{meta.error}</div>
      )}
    </div>
  );
};

export default CustomSelect;
