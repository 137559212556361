import { useState } from "react";
import { useField } from "formik";

import classes from "./Style.module.scss";
import { ReactComponent as EyeShowIcon } from "assets/icons/eye-show-icon.svg";
import { ReactComponent as EyeHideIcon } from "assets/icons/eye-hide-icon.svg";

const InputField = ({ name, ...rest }) => {
  const [field, meta] = useField(name);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => setPasswordShown(!passwordShown);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <input
        className={`${classes["outlined"]} ${
          meta.touched && meta.error ? classes.error : ""
        }`}
        type={passwordShown ? "text" : "password"}
        {...field}
        {...rest}
      />
      <div className={classes["eye-icon-wrap"]} onClick={togglePassword}>
        {passwordShown ? <EyeShowIcon /> : <EyeHideIcon />}
      </div>
      {meta.touched && meta.error && (
        <div className={classes["text-field-error"]}>{meta.error}</div>
      )}
    </div>
  );
};

export default InputField;
