import { useField } from "formik";
import TextareaAutosize from "react-textarea-autosize";

import classes from "./Style.module.scss";

const TextArea = ({
  name,
  variant = "standard",
  maxRows = 30,
  minRows = 3,
  strCount = 199,
  ...rest
}) => {
  const [field, meta] = useField(name);
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <TextareaAutosize
        className={`${classes[`textarea-${variant}`]} ${
          meta.touched && meta.error ? classes.error : ""
        }`}
        maxRows={maxRows}
        minRows={minRows}
        {...field}
        {...rest}
      />
      {strCount && (
        <div className={classes.count}>
          {field.value.length}/{strCount}
        </div>
      )}
      {meta.touched && meta.error && (
        <div className={classes["text-field-error"]}>{meta.error}</div>
      )}
    </div>
  );
};

export default TextArea;
