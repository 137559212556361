import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

const MATCHING_ROLE = {
  sale: { api: "sales", nameId: "salesId" },
  author: { api: "authors", nameId: "authorId" },
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage("tokens", null);
  const navigate = useNavigate();

  const user = useMemo(() => {
    if (!token) return { role: "public" };
    const data = JSON.parse(atob(token.accessToken.split(".")[1]));
    return {
      id: { name: MATCHING_ROLE[data.role].nameId, value: data.id },
      role: data.role,
      apiRole: MATCHING_ROLE[data.role].api,
    };
  }, [token]);

  const login = (res) => {
    // const path = user?.role === "sale" ? "/" : "";
    setToken(res);
    navigate(`/dashboard`);
  };
  const registration = (res) => {
    setToken(res);
    navigate("/dashboard");
  };
  const logout = () => {
    setToken(null);
    navigate("/signin");
  };

  const values = { user, login, registration, logout };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
