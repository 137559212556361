import { useState } from "react";
import api from "services";

export const useSubmitForm = ({ method, path, callback }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    error && setError(null);

    for (var key in values) {
      if (typeof values[key] === "string") {
        values[key] = values[key].trim();
      }
    }

    api[method](path, values)
      .then((res) => callback(res))
      .catch((error) => {
        if (typeof error === "object") {
          const fields = error.reduce(
            (acc, value) => ({ ...acc, ...value }),
            {}
          );
          actions.setErrors(fields);
        } else setError(error);
      })
      .finally(() => setLoading(false));
  };

  return [{ loading, error }, handleSubmit];
};
