import { cloneElement, createElement } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as AvatarIcon } from "assets/icons/avatar-icon.svg";
import classes from "./Header.module.scss";
import { useAuth } from "hooks/useAuth";

const UserLink = ({ name }) => {
  return (
    <div className={classes.user}>
      <span>{name}</span>
      <div className={classes.avatar}>
        <AvatarIcon />
      </div>
    </div>
  );
};

const Tag = ({ tagName, children, ...props }) => {
  return createElement(tagName, props, children);
};

const navigate = {
  author: [
    // { link: "/dashboard", label: "Dashboard" },
    { tag: NavLink, link: "/dashboard/statistics", label: "Statistics" },
    { tag: NavLink, link: "/dashboard/library", label: "Library" },
    { tag: NavLink, link: "/dashboard/links", label: "Links" },
    { tag: NavLink, link: "/dashboard/access", label: "Access" },
    { tag: NavLink, link: "/dashboard/profile", Render: UserLink },
  ],
  sale: [
    { tag: NavLink, link: "/dashboard/links", label: "Links" },
    { tag: "button", label: "Logout" },
  ],
};

const headerLinksBaseRole = {
  author: "/dashboard/library",
  sale: "/dashboard/links",
};

const Header = () => {
  const { logout, user } = useAuth();

  return (
    <header className={classes.header}>
      <div className={`container ${classes["header-container"]}`}>
        <div className={classes.logo}>
          <NavLink to={headerLinksBaseRole[user.role]}>Logo</NavLink>
        </div>
        <div className={classes.menu}>
          <nav className={classes.navbar}>
            <ul className={classes["navbar-nav"]}>
              {navigate[user.role].map((props, index) => {
                const { tag, link, label, Render } = props;
                const elProps = link
                  ? {
                      to: link,
                      end: true,
                      className: ({ isActive }) =>
                        isActive ? classes.active : "",
                    }
                  : {
                      onClick: logout,
                    };

                return (
                  <li className={classes["navbar-nav__item"]} key={index}>
                    <Tag tagName={tag} {...elProps}>
                      {label ||
                        cloneElement(<Render />, { name: user.fullName })}
                    </Tag>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
