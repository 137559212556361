import { Link } from "react-router-dom";

import { Formik } from "formik";
import { getSignInSchema } from "validationSchemas";

import { TextField, PasswordField } from "components/FormFields";
import SubmitButton from "components/SubmitButton";

import { useSubmitForm } from "hooks/useSubmitform";
import { useAuth } from "hooks/useAuth";

import { ReactComponent as PlaceholderIcon } from "assets/icons/placeholder-icon.svg";
import classes from "./Auth.module.scss";
import CustomSelect from "components/FormFields/CustomSelect";

const roles = [
  { label: "Admin", value: "authors" },
  { label: "Sales", value: "sales" },
];

const SignInPage = () => {
  const { login } = useAuth();
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "login",
    path: "/signin",
    callback: (res) => login(res),
  });

  return (
    <div className={classes.flex}>
      <div className={classes.col}>
        <Link className={classes.logo} to="/">
          Logo
        </Link>
        <PlaceholderIcon className={classes["placeholder-icon"]} />
      </div>
      <div className={classes.col}>
        <div className={`${classes["form-container"]}`}>
          <h1 style={{ marginBottom: "4rem" }}>Sign in</h1>
          <Formik
            initialValues={{
              role: "",
              email: "",
              password: "",
            }}
            validationSchema={getSignInSchema()}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, handleSubmit }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes["form-row"]}>
                  <label>Select role</label>
                  <CustomSelect
                    name="role"
                    defaultLabel="Role"
                    optionSelected={!!values.role}
                    options={roles}
                    onChange={(e) => setFieldValue("role", e.target.value)}
                  />
                </div>
                <div className={classes["form-row"]}>
                  <label htmlFor="email">E-mail</label>
                  <TextField id="email" type="email" name="email" />
                </div>
                <div className={classes["form-row"]}>
                  <label htmlFor="password">Password</label>
                  <PasswordField id="password" name="password" />
                </div>
                <div
                  className={`${classes["form-row"]} ${classes["form-row__special"]}`}
                >
                  {/* <Link to={"/reset"}>Forgot password?</Link> */}
                </div>
                <SubmitButton
                  errorMessage={error}
                  loading={loading}
                  type="submit"
                  classContainer={classes["btn-wrap"]}
                >
                  Sign in
                </SubmitButton>
                {/* <p className={classes.link}>
                  Don’t have an account? <Link to={"/signup"}>Sign up</Link>
                </p> */}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
