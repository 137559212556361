import { useField } from "formik";

import classes from "./Style.module.scss";

const CustomRadio = ({ className = "", ...restProps }) => {
  return (
    <input
      className={`${classes["custom-radio"]}${
        className ? ` ${className}` : ""
      }`}
      type="radio"
      {...restProps}
    />
  );
};

export const RadioValid = ({ name, ...restProps }) => {
  const [fields, meta] = useField(name);

  return (
    <CustomRadio
      className={meta.touched && meta.error ? classes.error : ""}
      {...fields}
      {...restProps}
    />
  );
};

export default CustomRadio;
