import { useState, useRef, useEffect } from "react";
import { useField } from "formik";

import classes from "./Style.module.scss";

const PreviewVideo = ({ file }) => {
  const [preview, setPreview] = useState(null);
  useEffect(() => {
    const getFile = async () => {
      setPreview({ src: file.url, size: file.size, name: file.name });
    };
    getFile();
  }, [file]);

  return <>{preview && preview.name}</>;
};

const FileUpload = ({ name, setFieldTouched, ...rest }) => {
  const [{ value, ...field }, meta] = useField(name);
  const fileRef = useRef();

  const handleUploadFile = () => {
    fileRef.current.click();
    !meta.touched && setFieldTouched("file_data", true, false);
  };

  return (
    <div className={classes["file-upload-container"]}>
      <div
        className={`${classes["file-upload"]} ${
          meta.touched && meta.error ? classes.error : ""
        }`}
      >
        <input type="file" ref={fileRef} hidden {...field} {...rest} />
        <button type="button" onClick={handleUploadFile}>
          Upload
        </button>

        <span>{value ? <PreviewVideo file={value} /> : "Choose video..."}</span>

        {meta.touched && meta.error && (
          <p className={classes["text-field-error"]}>{meta.error}</p>
        )}
      </div>
      <p>Format: Mp3, Avi. Size: Min 10MB and Max 5GB</p>
    </div>
  );
};

export default FileUpload;
