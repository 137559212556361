const PageState = ({ children }) => {
  return (
    <div
      style={{
        paddingTop: "5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

export default PageState;
