// import { useState, useEffect } from "react";

// const getStorageValue = (key, defaultValue) => {
//   if (typeof window !== "undefined") {
//     const item = localStorage.getItem(key);
//     return item ? JSON.parse(item) : defaultValue;
//   }
// };

// export const useLocalStorage = (key, defaultValue) => {
//   const [value, setValue] = useState(() => {
//     return getStorageValue(key, defaultValue);
//   });

//   useEffect(() => {
//     localStorage.setItem(key, JSON.stringify(value));
//   }, [key, value]);

//   return [value, setValue];
// };

import { useState } from "react";

export const useLocalStorage = (keyName, defaultValue) => {
  const [value, setStoredValue] = useState(() => {
    try {
      const value = localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [value, setValue];
};
