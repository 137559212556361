class Api {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.refreshToken = false;
    this.ERROR_MESSAGE = "Something went wrong. Please try again";
  }

  // default method
  async getToken() {
    // if (this.refreshToken) {
    //   // console.log(this.refreshToken);
    //   return new Promise((resolve, reject) => {
    //     setTimeout(async () => {
    //       try {
    //         const res = await this.getToken();
    //         resolve(res);
    //       } catch (e) {
    //         reject(e);
    //       }
    //     }, 700);
    //   });
    // }

    const { accessToken, refreshToken } = JSON.parse(
      localStorage.getItem("tokens")
    );
    const user = JSON.parse(atob(accessToken.split(".")[1]));
    const currentTimestamp = new Date().getTime() / 1000;
    const tokenIsExpired = user.exp < currentTimestamp;
    if (tokenIsExpired) {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000);
      // this.refreshToken = true;
      const res = await fetch(`${this.baseUrl}/refresh`, {
        method: "POST",
        headers: {
          Authorization: refreshToken,
        },
        signal: controller.signal,
      });

      // this.refreshToken = false;
      clearTimeout(timeoutId);
      if (res.status === 400) {
        localStorage.setItem("tokens", null);
        window.location.reload();
      }
      if (!res.ok) throw res.status;
      const tokens = await res.json();
      localStorage.setItem("tokens", JSON.stringify(tokens));
      return tokens.accessToken;
    }
    return accessToken;
  }

  async #fetch(params) {
    const { role = "private", endpoint, method, payload = false } = params;
    try {
      const api = `${this.baseUrl}${endpoint}`;
      const options = {};
      options.method = method;
      if (role === "private") {
        const accessToken = await this.getToken();
        options.headers = {
          ...options.headers,
          Authorization: `${accessToken}`,
        };
      }
      const methodsListSend = ["POST", "PATCH", "PUT", "DELETE"];
      if (methodsListSend.includes(method)) {
        if (payload) {
          options.headers = {
            ...options.headers,
            "Content-Type": "application/json",
          };
          options.body = JSON.stringify(payload);
        }
      }

      const response = await fetch(api, options);
      if (!response.ok) throw response;
      return [null, response];
    } catch (error) {
      return [error, null];
    }
  }

  // user API
  async registration(endpoint, values) {
    const [error, data] = await this.#fetch({
      role: "public",
      endpoint: endpoint,
      method: "POST",
      payload: values,
    });
    if (error) {
      if (error.status === 406) throw "Registration not possible";
      if (error.status === 400) {
        const { invalidFields } = await error.json();
        if (invalidFields) throw invalidFields;
        // if (msg) throw this.ERROR_MESSAGE;
      }
      throw this.ERROR_MESSAGE;
    }
    return await data.json();
  }

  async login(endpoint, values) {
    const { role, ...restValues } = values;

    const [error, data] = await this.#fetch({
      role: "public",
      endpoint: `/${role}${endpoint}`,
      method: "POST",
      payload: restValues,
    });
    if (error) {
      if (error.status === 400) {
        throw "The email address or password is incorrect. Please retry...";
      }
      throw this.ERROR_MESSAGE;
    }
    return await data.json();
  }

  async sendEmailToResetPass(endpoint, values) {
    return values;
  }

  async resendEmail(endpoint, values) {
    return values;
  }

  async resetPassword(endpoint, values) {
    return values;
  }

  async updateUser(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "PATCH",
      payload: values,
    });
    if (error) {
      if (error.status === 400) {
        const { invalidFields } = await error.json();
        if (invalidFields) throw invalidFields;
        // if (msg) throw this.ERROR_MESSAGE;
      }
      throw this.ERROR_MESSAGE;
    }
    return data.statusText;
  }

  // access page Api

  async getAllSales(endpoint) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "GET",
    });
    if (error) throw this.ERROR_MESSAGE;
    const data = await res.json();
    return data;
  }
  async createSales(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "POST",
      payload: values,
    });
    if (error) {
      if (error.status === 400) throw await this.#getInvalidFields(error);
      throw this.ERROR_MESSAGE;
    }
    return data.statusText;
  }
  async updateSales(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "PATCH",
      payload: values,
    });
   if (error) {
     if (error.status === 400) throw await this.#getInvalidFields(error);
     throw this.ERROR_MESSAGE;
   }
    return data.statusText;
  }
  async deleteSales(endpoint) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "DELETE",
    });
    if (error) throw error.status;
    return res.text;
  }

  // video page API
  async createVideo(endpoint, values) {
    const { file_data, ...restValues } = values;
    restValues.size = file_data.size;

    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "POST",
      payload: restValues,
    });
    if (error) {
      if (error.status === 400) {
        const { invalidFields } = await error.json();
        if (invalidFields) throw invalidFields;
      }
      throw this.ERROR_MESSAGE;
    }
    return await data.json();
  }
  async updateVideo(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "PATCH",
      payload: values,
    });
    if (error) {
      throw this.ERROR_MESSAGE;
    }
    return data.text;
  }

  //test page API
  async createTest(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "POST",
      payload: values,
    });
    if (error) {
      if (error.status === 400) throw await this.#getInvalidFields(error);
      throw this.ERROR_MESSAGE;
    }
    return data.statusText;
  }
  async updateTest(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "PUT",
      payload: values,
    });
    if (error) {
      if (error.status === 400) throw await this.#getInvalidFields(error);
      throw this.ERROR_MESSAGE;
    }
    return data.statusText;
  }

  async getData(endpoint) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "GET",
    });
    if (error) throw this.ERROR_MESSAGE;
    return await data.json();
  }

  // pack page API
  async getAllPacks(endpoint) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "GET",
    });
    if (error) throw this.ERROR_MESSAGE;
    const data = await res.json();
    return data;
  }
  async createPack(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "POST",
      payload: values,
    });
    if (error) {
      if (error.status === 400) throw await this.#getInvalidFields(error);
      throw this.ERROR_MESSAGE;
    }
    const { id } = await data.json();
    return { title: values.title, pageId: id };
  }
  async putModules(endpoint, values) {
    const [error, _] = await this.#fetch({
      endpoint: endpoint,
      method: "PUT",
      payload: values,
    });
    if (error) {
      if (error.status === 400) {
        const { invalidFields, msg } = await error.json();
        if (invalidFields) throw invalidFields[0];
        if (msg) throw msg;
      }
      throw this.ERROR_MESSAGE;
    }
  }
  async updatePack(endpoint, values) {
    try {
      const accessToken = await this.getToken();
      const res = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "PATCH",
        headers: {
          Authorization: `${accessToken}`,
        },
        body: values,
      });
      if (!res.ok) throw res;
      return res.statusText;
    } catch (error) {
      if (error.status === 400) {
        const { invalidFields } = await error.json();
        if (invalidFields) throw invalidFields;
      }
      throw this.ERROR_MESSAGE;
    }
  }
  async getBlobImage(endpoint) {
    try {
      const accessToken = await this.getToken();
      const res = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!res.ok) throw res.status;
      return await res.blob();
    } catch (error) {
      throw error;
    }
  }
  async deletePack(endpoint) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "DELETE",
    });
    if (error) throw error.status;
    return res.text;
  }

  async dublicatePack(endpoint) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "POST",
    });
    if (error) throw error.status;
    return res.text;
  }

  //All file API
  async getAllFiles(endpoint) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "GET",
    });
    if (error) throw this.ERROR_MESSAGE;
    const data = await res.json();
    const newQuizzes = data.quizzes.map((item) => ({
      ...item,
      type: "quiz",
      checked: false,
    }));
    const newVideos = data.videos.map((item) => ({
      ...item,
      type: "video",
      checked: false,
    }));

    const allData = [...newVideos, ...newQuizzes];
    return allData;
  }
  async deleteItemsInAllFiles(endpoint, values) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "DELETE",
      payload: values,
    });
    if (error) throw error.status;
    return res.text;
  }

  //Payments Api
  async getPayments(endpoint) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "GET",
    });
    if (error) throw this.ERROR_MESSAGE;
    const data = await res.json();
    return data;
  }

  async createPayment(endpoint, values) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "POST",
      payload: values,
    });
    if (error) {
      if (error.status === 400) throw await this.#getInvalidFields(error);
      throw this.ERROR_MESSAGE;
    }
    return res.statusText;
  }

  //stats API
  async getAllStats() {
    return { key: 123, data: { women: 100, men: 200 } };
  }

  async getStatistics(endpoint) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "GET",
    });
    if (error) throw this.ERROR_MESSAGE;
    return await res.json();
  }

  async toggleStudentAccess(endpoint, values) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "PATCH",
      payload: values,
    });
    if (error) throw this.ERROR_MESSAGE;
    return await res.statusText;
  }

  async addNewCoursesForStudent(endpoint, values) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "POST",
      payload: values,
    });
    if (error) {
      if (error.status === 400) throw await this.#getInvalidFields(error);
      throw this.ERROR_MESSAGE;
    }
    return await res.json();
  }

  async deleteStudentCourse(endpoint, values) {
    const [error, res] = await this.#fetch({
      endpoint: endpoint,
      method: "DELETE",
      payload: values,
    });
    if (error) throw this.ERROR_MESSAGE;
    return await res.statusText;
  }

  async #getInvalidFields(error) {
    const { invalidFields, msg } = await error.json();
    if (invalidFields) return invalidFields;
    if (msg) return msg;
  }
}

// export default new Api("https://devapiplatform.dispatch42.school");
// export default new Api("http://192.168.1.53:3000");
export default new Api("https://apiedu.trucking42.school");