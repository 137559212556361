import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import classes from "./HomePage.module.scss";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/signin");
  }, [navigate]);
};

export default HomePage;
