import PhoneInput from "react-phone-input-2";
import { useField } from "formik";
import classes from "./Style.module.scss";

const PhoneField = ({
  name,
  onChange,
  variant = "outlined",
  country = "us",
  ...rest
}) => {
  const [{ onBlur, value }, meta] = useField(name);
  const isError = meta.touched && meta.error ? classes.error : "";
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <PhoneInput
        containerClass={classes["phone-container"]}
        inputClass={`${classes[variant]} ${isError}`}
        onChange={(value, country, e, formattedValue) => {
          onChange(formattedValue);
        }}
        value={value}
        onBlur={onBlur}
        country={country}
        inputProps={{
          ...rest,
        }}
      />
      {meta.touched && meta.error && (
        <div className={classes["text-field-error"]}>{meta.error}</div>
      )}
    </div>
  );
};

export default PhoneField;
