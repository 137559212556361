import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { UserProvider } from "hooks/useUser";

import Header from "components/Header";
import Spinner from "components/Spinner/Spinner";
import PageState from "components/PageState/PageState";

const PrivateLayout = () => {
  return (
    <UserProvider>
      <Header />
      <Suspense
        fallback={
          <PageState>
            <Spinner style={{ width: "5rem", height: "5rem" }} />
          </PageState>
        }
      >
        <Outlet />
      </Suspense>
    </UserProvider>
  );
};

export default PrivateLayout;
