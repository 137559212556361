import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthLayout from "layouts/AuthLayout";
import PrivateLayout from "layouts/PrivateLayout";

// lazy import pages
// const HomePage = lazy(() => import("views/HomePage"));
// const SignIn = lazy(() => import("views/Auth/SignIn"));
// const SignUp = lazy(() => import("views/Auth/SignUp"));
// const ResetPassword = lazy(() => import("views/Auth/ResetPassword"));
import HomePage from "views/HomePage";
import { SignIn } from "views/Auth";
// const DashboardPage = lazy(() => import("views/DashboardPage"));
const StatisticsPage = lazy(() => import("views/StatisticsPage"));
const LibraryPage = lazy(() => import("views/LibraryPage"));
const MyProfilePage = lazy(() => import("views/MyProfilePage"));
const VideoPage = lazy(() => import("views/VideoPage"));
const LinksPage = lazy(() => import("views/LinksPage"));
const TestPage = lazy(() => import("views/TestPage"));
const PackPage = lazy(() => import("views/PackPage"));
const AccessPage = lazy(() => import("views/AccessPage"));

// AUTHOR
const authorRoutes = [
  {
    path: "/dashboard",
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard/library" />,
      },
      {
        path: "statistics",
        element: <StatisticsPage />,
      },
      {
        path: "library",
        children: [
          { index: true, element: <LibraryPage /> },
          { path: "video", element: <VideoPage /> },
          { path: "test", element: <TestPage /> },
          { path: "pack", element: <PackPage /> },
        ],
      },
      {
        path: "profile",
        element: <MyProfilePage />,
      },
      {
        path: "links",
        element: <LinksPage />,
      },
      {
        path: "access",
        element: <AccessPage />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/dashboard/library" />,
  },
];
// SALES
const saleRoutes = [
  {
    path: "/dashboard/links",
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <LinksPage />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/dashboard/links" />,
  },
];
// PUBLIC
const publicRoutes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/signin",
        element: <SignIn />,
      },
      // {
      //   path: "/signup",
      //   element: <SignUp />,
      // },
      // {
      //   path: "/reset",
      //   element: <ResetPassword />,
      // },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

const ROLES_ROUTES = {
  author: authorRoutes,
  sale: saleRoutes,
  public: publicRoutes,
};

export const getRoutes = (role) => ROLES_ROUTES[role] ?? publicRoutes;
