import classes from "./SubmitButton.module.scss";

const SubmitButton = ({
  children,
  errorMessage = null,
  loading = false,
  disabled,
  className = "",
  classContainer = "",
  ...restProps
}) => {
  const inactive = disabled ?? loading;
  return (
    <div
      className={`${classes.container}${
        classContainer ? ` ${classContainer}` : ""
      }`}
    >
      {errorMessage && (
        <p className="err-message" style={{ margin: "0.8rem 0" }}>
          &#42; {errorMessage}
        </p>
      )}
      <button
        disabled={inactive}
        className={`${classes.style}${className ? ` ${className}` : ""}${
          inactive ? ` ${classes.inactive}` : ""
        }`}
        {...restProps}
      >
        {children}
      </button>
    </div>
  );
};

export default SubmitButton;
