import { createContext, useContext, useEffect, useState } from "react";
import PageState from "components/PageState/PageState";
import Spinner from "components/Spinner/Spinner";
import { useAuth } from "./useAuth";

import api from "services";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await api.getData(`/${auth.user.apiRole}/me`);
        setUser(res);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const showContent = !(loading || error) && user;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <>
        {loading && (
          <PageState>
            <Spinner style={{ width: "5rem", height: "5rem" }} />
          </PageState>
        )}

        {error && (
          <PageState>
            <p style={{ fontSize: "1.6rem" }}>
              Something went wrong. Please try again.
            </p>
          </PageState>
        )}
        {showContent && children}
      </>
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
