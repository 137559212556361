import { forwardRef } from "react";
import { useField } from "formik";

import classes from "./Style.module.scss";

export const InputBase = forwardRef(
  ({ variant = "outlined", ...props }, ref) => {
    return <input ref={ref} className={`${classes[variant]}`} {...props} />;
  }
);

const TextField = forwardRef(({ name, variant = "outlined", ...rest }, ref) => {
  const [field, meta] = useField(name);
  const existRef = ref ? { ref } : {};
  const isError = meta.touched && meta.error ? classes.error : "";

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <InputBase
        className={`${classes[variant]} ${isError}`}
        type="text"
        {...field}
        {...rest}
        {...existRef}
      />
      {meta.touched && meta.error && (
        <div className={classes["text-field-error"]}>{meta.error}</div>
      )}
    </div>
  );
});

export default TextField;
